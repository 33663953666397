import React from "react"
import Lottie from 'react-lottie-player';
import { FaSnapchat, FaFacebook, FaPinterest, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

import brainMountain from '../lottie/5ddf0b46cf77c626d901bf33_brain-mountain.json';

// https://github.com/FortAwesome/Font-Awesome/issues/14340#issuecomment-559469045
const TikTok = ({ size }) => (
  <svg width={size} height={size} viewBox="-2 -2 28 28">
    <path fill="white" d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z" />
  </svg>
)

const linkStyle = {
  marginRight: 10,
  color: 'white',
};

export default () => (
  <>
    <Lottie loop play animationData={brainMountain} style={{ width: 200, margin: '0 auto -30px auto' }} />

    <div style={{ width: '100%', maxWidth: 500, margin: '50px auto' }}>
      <div style={{ fontSize: 20, fontWeight: 300, maxWidth: 500, width: '100%', textAlign: 'center' }}>
        <p style={{ fontWeight: 700 }}>Welcome to the WiseGuide App.</p>
        <p>Thank you for your purchase!</p>
        
        <p>Well done! You’re showing up for your mind and starting to develop the habits that can help you become happier and healthier. All you have to do is just listen…</p>
      </div>
    </div>

    <div style={{ marginTop: 50, background: 'rgb(49, 192, 168)', color: 'white' }}>
      <div style={{ fontSize: 20, fontWeight: 300, maxWidth: 600, width: '100%', margin: 'auto', padding: '50px 20px' }}>
        <p style={{ fontWeight: 'bold' }}>Follow these three simple steps to begin your journey to create your best life:</p>
        
        <p><b>- Step One:</b> Check your email, we will be sending you your WiseGuide App password.</p>
        
        <p><b>- Step Two:</b> Download the Wise Guide App from <a style={{ fontWeight: 600, color: 'white' }} href="https://play.google.com/store/apps/details?id=com.wiseguide" target="_blank" rel="noopener noreferrer">Google Play</a> or the <a style={{ fontWeight: 600, color: 'white' }} href="https://apps.apple.com/us/app/wiseguide/id1459536021" target="_blank" rel="noopener noreferrer">Apple App Store</a>.</p>
        
        <p><b>- Step Three:</b> Sign in to the WiseGuide using your email and password.</p>
        
        <p>You can manage your subscription from the WiseGuide app website.</p>
        
        <p style={{ fontWeight: 'bold' }}>Thank you for joining our community. To stay connected, follow us on:</p>

        <div>
          <a style={linkStyle} target="_blank" rel="noopener noreferrer" href="https://www.snapchat.com/add/wiseguideapp"><FaSnapchat size={32} /></a>
          <a style={linkStyle} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/wiseguideapp/"><FaFacebook size={32} /></a>
          <a style={linkStyle} target="_blank" rel="noopener noreferrer" href="https://www.pinterest.com/wiseguideapp/"><FaPinterest size={32} /></a>
          <a style={linkStyle} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/thewiseguideapp/"><FaInstagram size={32} /></a>
          <a style={linkStyle} target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/19074941/"><FaLinkedin size={32} /></a>
          <a style={linkStyle} target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@wiseguideapp"><TikTok size={32} /></a>
          <a style={linkStyle} target="_blank" rel="noopener noreferrer" href="https://twitter.com/wiseguideapp/"><FaTwitter size={32} /></a>
        </div>
      </div>
    </div>
  </>
)