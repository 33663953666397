import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ThankYou from "../../components/ThankYou";


const ThankYouPage = () => (
  <Layout>
    <SEO title="Thank you" />

    <ThankYou />
  </Layout>
)

export default ThankYouPage
